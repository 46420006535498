import React, { createContext, useContext, useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    
    const navigate = useNavigate();
    const location = useLocation();
    const redirectPath = location.state?.path || "/";
    const initUser = {
        username: window.sessionStorage.getItem('username') || '',
        permissions:[]
    }
    const [user, setUser] = useState(initUser);

    useEffect(() => {
        if (window.sessionStorage.getItem('username'))
        {
            setUser({username:window.sessionStorage.getItem('username'), permissions:["view_extra"] })
        }
    },[])
    const login = (user,password) => {
        // if (user === "admin") {
        //     setUser({ username: user, permissions: ["view_extra"] });
        // }
        if (user === "user01" && password === "gr1dU53r"){
            window.sessionStorage.setItem('username',user)
            setUser({username:user, permissions:["view_extra"]})
        }
        // else {
        //     setUser({ username: user, permissions: ["view_about"] });
        // }
        navigate(redirectPath, { replace: true });
    };
    const logout = () => {
        window.sessionStorage.removeItem('username')
        setUser({ username: "", permissions: [] })
        navigate("/",{replace:true});
    };
    return (
        <AuthContext.Provider value={{ user, login, logout }}>
            {children}
        </AuthContext.Provider>
    )
};

export const useAuth = () => {
    return useContext(AuthContext);
};