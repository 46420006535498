import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight } from '@fortawesome/free-solid-svg-icons'

const BgTextContainer = ({bg,text,title,heading}) => {

    const handleDownload = (pdfFile) => {
        fetch(`assets/file/${pdfFile}`).then(response => {
            response.blob().then(blob => {
                const fileURL = window.URL.createObjectURL(blob)

                let alink = document.createElement('a')
                alink.href = fileURL
                alink.download = pdfFile
                alink.click()
            })
        })
    }
    const content = (
        <>
            <div className='bgtextcontainer'>
                <div className='bgtextcontainer-bg'>
                    <img src={bg}/>
                </div>
                <div className='bgtextcontainer-container'>
                    <div className='bgtextcontainer-wrapper'>
                        <div className='bgtextcontainer-content'>
                            <div className='bgtextcontainer-heading'>
                                <span>{heading}</span>
                            </div>
                            <div className='bgtextcontainer-title'>
                                <h2>{title}</h2>
                            </div>
                            <div className='bgtextcontainer-text'>
                                {text}
                            </div>
                            <div className='bgtextcontainer-btn'>
                                <button onClick={() => {handleDownload('apply_grid.pdf')}} type="button" className="flex justify-center">
                                    <div className="btn_readmore btn_readmore-dark leading-[1.4]">Read More</div>
                                    <div className="inline-flex h-full border border-solid border-gray-100 py-3 px-4 text-gray-100 rounded-tr rounded-br">
                                        <FontAwesomeIcon icon={faAngleRight} />
                                    </div>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
    return content
}

export default BgTextContainer