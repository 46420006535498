
const Footer = () => {
    const content = (
        <>
            <div className="footer">
                <div className="footer-container">
                    <div className="footer-wrapper">
                        <div className="footer-text">
                            <div className="footer-text_copyright">
                                <span>© <a href="http://bireka.id"><span className="font-bold text-red-700">bireka.id</span></a>. All Rights Reserved </span>
                            </div>
                        </div>
                        <div className="footer-logo">
                            <img src="./assets/img/grid.png" alt="bireka_logo"/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
    return content
}

export default Footer