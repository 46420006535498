import { useState,useEffect,  } from 'react'
import TechnologyItem from '../components/TechnologyItem'
import PdfViewer from '../components/PdfViewer'

const TechnologyContainer = () => { 

    const data = [
        {
            title:'Charging Point v1',
            downloadFile:'chargingpointv1.pdf'
        },
        {
            title:'32 Points Control Block',
            downloadFile:'32points_control_block.pdf'
        },
        {
            title:'Product Brochure',
            downloadFile:'brochure.pdf'
        },
        {
            title:'Installation Illustration 32 Points',
            downloadFile:'installation_illustration_32p.pdf'
        },
        {
            title:'Installation Topology 12 Core',
            downloadFile:'installation_topology_12c.pdf'
        },
        {
            title:'Technical Specification',
            downloadFile:'tech_spec.pdf'
        },
        {
            title:'Sample Installation',
            downloadFile:'sampleinstallation.pdf'
        },
    ]

    const [indexActive, setIndexActive] = useState(null)
    const [pdfActive,setPdfActive] = useState({})

    useEffect(() => {
        setPdfActive(data[indexActive])
    },[indexActive])

    const changePdfSet = (id) => {
        setIndexActive(id)
    }

    const selectBoxChanged = (e) => {
        const id = parseInt(e.target.value)
        console.log('id is ',id)
        changePdfSet(id)
    }

    const handleDownload = () => {
        const pdfFile = pdfActive?.downloadFile
        
        if (pdfFile)
        {
            fetch(`assets/file/${pdfFile}`).then(response => {
                response.blob().then(blob => {
                    const fileURL = window.URL.createObjectURL(blob)
    
                    let alink = document.createElement('a')
                    alink.href = fileURL
                    alink.download = pdfFile
                    alink.click()
                })
            })
        }
    }

    

    console.log('pdf active is ',indexActive)

  return (
    <section className='block bg-slate-200 min-h-[calc(100vh_-_164px)]'>
        
        <div className='p-4'>
            <div className='mx-auto max-w-[1200px]'>
                <div className='relative'>
                    <div className='grouptext-head__title'>
                        <h2>Technology</h2>
                    </div>
                    <div className="relative w-[100%] my-[20px] h-[6px] before:absolute before:top-[5px] before:left-0 before:h-[1px] before:w-[100%] before:bg-black before:z-10 before:content-['']">
                        <div className="absolute top-[0px] left-0 h-[6px] w-[50px] bg-black">
                        </div>
                    </div>
                </div>
                <div className='lg:hidden'>
                    <div className='pb-4'>
                        <select className='w-full py-2 px-2' name="" onChange={selectBoxChanged} value={indexActive ? indexActive : ''}>
                            <option value={''}>Choose File...</option>
                            {
                                data.map((data,id) => {
                                    return (<option key={id} value={id}>{data.title}</option>)
                                })
                            }
                            
                        </select>
                    </div>
                    <div>
                        <button className='button w-full' onClick={handleDownload}>Download</button>
                    </div>
                    {/* select that only shown when in mobile */}
                </div>
                <div className='pt-8'>
                    <div className='flex justify-between'>
                        <div className='lg:w-[23%] hidden lg:block w-[0%] max-h-[1000px]'>
                            <div className='flex flex-wrap justify-between'>
                                {
                                    data.map((data,id) => {
                                        return (<TechnologyItem key={id} title={data.title} downloadFile={data.downloadFile} id={id} setActive={changePdfSet} />)
                                    })
                                }
                            </div>
                        </div>
                        <div className='w-full lg:w-[75%] max-h-[700px] overflow-y-scroll bg-white'>
                            <PdfViewer pdfFile={pdfActive?.downloadFile} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}

export default TechnologyContainer