import MainPage from './pages/MainPage';
import Apply from './pages/Apply';
import LocationContainer from './container/LocationContainer';
import Booking from './pages/Booking';
import Layout from './components/Layout';
import Order from './pages/Order';
import Login from './pages/Login';
import Authentication from './routes/Authentication';
import Technology from './pages/Technology';
import { Routes, Route } from 'react-router-dom';
import Pdfviewer from './pages/Pdfviewer';


function App() {
  return(
      <Routes>
        <Route path="/" element={<Layout/>}>
          <Route index element={<MainPage title="Home | Grid"/>} />
          <Route path='apply' element={<Apply title="Apply | Grid"/>} />
          <Route path='map'>
            <Route index element={<Authentication><LocationContainer  parentToggle={true}/></Authentication>} />
            {/* <Route path=':id' element={<Authentication><LocationContainer  parentToggle={true}/></Authentication>} /> */}
          </Route>
          <Route path="booking">
            <Route index element={<Authentication><Booking /></Authentication>}/>
            {/* <Route path=':id' element={<Authentication><Booking /></Authentication>}/> */}
          </Route>
          <Route path="technology">
            <Route index element={<Technology />}/>
          </Route>
          <Route path="pdfviewer">
            <Route index element={<Pdfviewer />}/>
          </Route>

          <Route path='order'>
            <Route index element={<Authentication><Order /></Authentication>}/>
          </Route>

          <Route path='login' element={<Login/>} />
        </Route>
        
      </Routes>
  );
}

export default App;