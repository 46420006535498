import Navbar from "../components/Navbar"
import BookingContainer from "../container/BookingContainer"
// import { SITES } from "../config/gridSite"
import { SITES } from "../config/gridSiteDemo"
import { useParams , useNavigate, useSearchParams } from "react-router-dom"
import { useState, useEffect} from 'react'


const Booking = () => {

    const [searchParams] = useSearchParams()
    const id = searchParams.get('site')

    const navigate = useNavigate()

    const [siteSelected, setSiteSelected] = useState({})

    // console.log(siteSelected)

    useEffect(() => {
        if (!id)
        {
            navigate('/')
        }
        else
        {
            const site = SITES.filter(o => {
                return o.siteId === id
            })

            if (!site[0])
            {
                navigate('/')
            }
            setSiteSelected(site[0])
        }
    },[id])

    
    const content = (
        <>
            <Navbar />
            <BookingContainer site={siteSelected}/>
        </>
    )
    return  content
}

export default Booking