import  axios  from "axios"
import { useState } from "react"

const ContactUsForm = () => {

    const baseUrl = "http://170.64.161.31:3500/sendemail"

    const [firstname,setFirstname] = useState("")
    const [lastname,setLastname] = useState("")
    const [email,setEmail] = useState("")
    const [message,setMessage] = useState("")
    const [btnDisable, setBtnDisable] = useState(false)
    

    const onFirstnameChanged = (e) => {
        setFirstname(e.target.value)
    }
    
    const onLastnameChanged = (e) => {
        setLastname(e.target.value)
    }
    
    const onEmailChanged = (e) => {
        setEmail(e.target.value)
    }
    
    const onMessageChanged = (e) => {
        setMessage(e.target.value)
    }

    const [errClass,setErrClass] = useState('hidden');
    const [errClassColor,setErrClassColor] = useState('');
    const [errContent,setErrContent] = useState('');
    

    
    const onSendMessageClicked = async () => {
        console.log('sending message here...')
        setBtnDisable(true)
        axios
            .post(baseUrl,{firstname,lastname,email,message})
            .then((response) => {
                console.log(response)
                setErrClass("block")
                setErrClassColor("bg-green-600")
                setErrContent(response.data.message)
                setFirstname("")
                setLastname("")
                setEmail("")
                setMessage("")
                setBtnDisable(false)
            })
            .catch(error => {
                console.log(error.response.data.message)
                setErrClass("block")
                setErrClassColor("bg-red-600")
                setErrContent(error.response.data.message)
                setBtnDisable(false)
            })
    }   

    const content = (
        <>
            <div className="contactus-title">
                <h2>Contact Us</h2>
            </div>
            <div className={errClass}>
                <div className={`contactus-err + ${errClassColor}`}>
                    <span>{errContent}</span>
                </div>
            </div>
            <form className="contactus_form" onSubmit={e => e.preventDefault()}>
                <div className="contactus_form-group form_input relative">
                    <label className="contactus_form-label" htmlFor="firstname">First Name</label>
                    <input className="contactus_form-input" id="firstname" name="firstname" type="text" autoComplete="off" placeholder="Your first name" value={firstname} onChange={onFirstnameChanged}/>
                </div>
                <div className="contactus_form-group form_input relative">
                    <label className="contactus_form-label" htmlFor="lastname">Last Name</label>
                    <input className="contactus_form-input" id="lastname" name="lastname"  type="text" autoComplete="off" placeholder="Your last name"  value={lastname} onChange={onLastnameChanged} />
                </div>
                <div className="contactus_form-group form_input relative">
                    <label className="contactus_form-label" htmlFor="email">Email Address</label>
                    <input className="contactus_form-input" id="email" name="email" type="email" autoComplete="off" placeholder="Your Email Address"  value={email} onChange={onEmailChanged} />
                </div>
                <div className="contactus_form-group">
                    <label className="contactus_form-label" htmlFor="">Message</label>
                    <textarea className="contactus_form-textarea" id="message" name="message" placeholder="Message here..." rows={5}  value={message} onChange={onMessageChanged}>
                    </textarea>
                </div>
                <div className="contactus_form-group">
                    <button className="button" type="button" disabled={btnDisable} onClick={onSendMessageClicked}>Send Message</button>
                </div>
            </form>
        </>
    )
    return content
}

export default ContactUsForm