import React from 'react'
import {useState,useEffect} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose , faAlignLeft, faChargingStation } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';


const ModalSite = ({site,active,onCloseClick}) => {
    const modalClass = active ? "active" : ""

    const navigate = useNavigate()

    const [full,setFull] = useState(false)

    useEffect(() => {
      if (site.numberOfAvailableChargers === 0)
      {
        setFull(true)
      }
      else{
        setFull(false)
      }
    }, [site.numberOfAvailableChargers])

    const onReserveClick = () => {
      navigate(`/booking?site=${site.siteId}`)
    }
    
    const content = (
        <>
          <div className={`modalsite-container ${modalClass}`}>
            <div className='absolute top-0 left-0 w-full h-[80px]'>
              <div className="flex justify-end cursor-pointer pr-2" onClick={onCloseClick}>
                <span className='text-2xl'>
                        <FontAwesomeIcon icon={faClose}/>
                    </span>
              </div>
            </div>
            
            <div className='modalsite-bg h-[250px] bg-slate-400' style={{backgroundImage:""}}>
              
            </div>
            <div className="modalsite-second bg-gray-300 flex justify-between py-2 px-2">
              <div className="modalsite-second__name flex items-center">
                <span className='text-2xl font-bold'>{site.siteName}</span>
              </div>
              <div className="modalsite-second__btn flex items-center">
                <button className='button' disabled={full} onClick={onReserveClick}>Reserve</button>
              </div>
            </div>
            <div className="modalsite-third">
              <div className="modalsite-third__wrapper">
                <div className="modalsite-third__label">
                  <span>Available</span>
                </div>
                <div className="modalsite-third__val">
                  <span>{site.numberOfAvailableChargers}</span>
                </div>
              </div>
              <div className="modalsite-third__wrapper">
                <div className="modalsite-third__label">
                  <span>Total</span>
                </div>
                <div className="modalsite-third__val">
                  <span>{site.numberOfTotalChargers}</span>
                </div>
              </div>
            </div>
            <div className='modalsite-forth'>
              <div className="modalsite-forth__wrapper">
                <div className="modalsite-forth__icon">
                  <span className='text-red-600'>
                      <FontAwesomeIcon icon={faChargingStation}/>
                  </span>
                </div>
                <div className="modalsite-forth__label address">
                  <span>{site.siteAddress}</span>
                </div>
              </div>
              <div className="modalsite-forth__wrapper">
                <div className="modalsite-forth__icon">
                  <span className='text-red-600'>
                      <FontAwesomeIcon icon={faAlignLeft}/>
                  </span>
                </div>
                <div className="modalsite-forth__label">
                  <span>{site.siteDescription}</span>
                </div>
              </div>
            </div>
            <div className="modalsite-fifth">
              <div className="modalsite-fifth__title">
                <span>Connectors</span>
              </div>
              <div className="modalsite-fifth__wrapper">
                <div className="modalsite-fifth__logo">
                  <img src={`${window.location.origin}/assets/img/connector/cee74.png`} alt="logo" />
                </div>
                <div className="modalsite-fifth__label">
                  <div>
                    <div className='modalsite-fifth__name'>
                      <span>CEE 7/4 16A SCHUTZKONTAKT</span>
                    </div>
                    <div className='modalsite-fifth__available'>
                      <span>{`${site.numberOfAvailableChargers}/${site.numberOfTotalChargers}`}</span>
                    </div>
                  </div>
                </div>
                <div className="modalsite-fifth__badge">
                  <div className='badge badge-success'>
                    <span>available</span>
                  </div>
                </div>
              </div>
              <div className="modalsite-fifth__wrapper">
                <div className="modalsite-fifth__logo">
                  <img src={`${window.location.origin}/assets/img/connector/iec60309.jpg`} alt="logo" />
                </div>
                <div className="modalsite-fifth__label">
                  <div>
                    <div className='modalsite-fifth__name'>
                      <span>IEC 60309 32A 2P+E</span>
                    </div>
                    <div className='modalsite-fifth__available'>
                      <span>{`${site.numberOfAvailableChargers}/${site.numberOfTotalChargers}`}</span>
                    </div>
                  </div>
                </div>
                <div className="modalsite-fifth__badge">
                  <div className='badge badge-success'>
                    <span>available</span>
                  </div>
                </div>
              </div>
              <div className="modalsite-fifth__wrapper">
                <div className="modalsite-fifth__logo">
                  <img src={`${window.location.origin}/assets/img/connector/mennekes.jpg`} alt="logo" />
                </div>
                <div className="modalsite-fifth__label">
                  <div>
                    <div className='modalsite-fifth__name'>
                      <span>IEC 62196 TYPE 2 (Mennekes)</span>
                    </div>
                    <div className='modalsite-fifth__available'>
                      <span>{`${site.numberOfAvailableChargers}/${site.numberOfTotalChargers}`}</span>
                    </div>
                  </div>
                </div>
                <div className="modalsite-fifth__badge">
                  <div className='badge badge-success'>
                    <span>available</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="modalsite-img">
              <img alt="grid_logo" src={`${window.location.origin}/assets/img/grid.png`} />
            </div>
          </div>
          <div className={`${modalClass} modalsite`} onClick={onCloseClick}>
          </div>
        </>
      )
    return content
}

export default ModalSite