import React from 'react'
import BookSummary from '../components/BookSummary'
import BookBottom from '../components/BookBottom'
import { useAuth } from '../provider/AuthProvider'
import { useState,useEffect } from 'react'


const OrderContainer = ({site,chargePoint}) => {

    const {user} = useAuth()
    const [positionValue,setPositionValue] = useState(1)
    const [connectorValue,setConnectorValue] = useState(1)
    const [amountValue,setAmountValue] = useState('')
    const [disableAmount,setDisableAmount] = useState(true)
    const [summaryArr, setSummaryArr] = useState([])
    const [totalVal,setTotalVal] = useState(0)
    const [totalNonPpn,setTotalNonPpn] = useState(0)
    const [ppn,setPpn] = useState(0)
    
    console.log(positionValue)
    const onOptionPositionChange = (e) => {
        setPositionValue(e.target.value)
    }
    
    const onOptionConnectorChange = (e) => {
        setConnectorValue(e.target.value)
    }

    const onAmountChange = (e) => {
        const result = e.target.value.replace(/[^1-9]+[^0-9]/g, '')
        setAmountValue(result)
    }


    useEffect(() => {
        let result;
        if (positionValue == 1)
        {
            result = true
            setAmountValue('')
            setPpn(0)
            setTotalVal(0)
            setTotalNonPpn(0)
        }
        else if (positionValue == 2)
        {
            result = false
            setPpn(amountValue*0.11)
            setTotalVal((amountValue*1+(amountValue*0.11)))
            setTotalNonPpn((amountValue*1))
        }
        else if (positionValue == 3)
        {
            result = false
            setPpn(amountValue*2475*0.11)
            setTotalVal((amountValue*2475+(amountValue*2475*0.11)))
            setTotalNonPpn((amountValue*2475))
        }
        setDisableAmount(result)
    },[positionValue,amountValue])

    useEffect(() => {
        if (positionValue == 1)
        {
            setSummaryArr([{id:positionValue,label:"Full Charge",val:0},{id:999,label:"PPN",val:ppn}])
        }
        else if (positionValue == 2)
        {
            setSummaryArr([{id:positionValue,label:"Price",val:amountValue},{id:'3',label:"Total Kwh",val:(Number(amountValue/2475).toFixed(2))},{id:999,label:"PPN",val:ppn}])
        }
        else if (positionValue == 3)
        {
            setSummaryArr([{id:2,label:"Price",val:(amountValue*2475)},{id:positionValue,label:"Total Kwh",val:amountValue},{id:999,label:"PPN",val:ppn}])
        }
        // setSummaryArr([...summaryArr,{id:999,label:"PPN",val:ppn}])
    },[positionValue,ppn])



    const content = (
        <div className='order pt-[70px] lg:pt-[100px] min-h-[100vh] bg-slate-100'>
            <div className='order-bg'>
                <div className='order-bg__video relative h-full'  dangerouslySetInnerHTML={{
                    __html:
                    `
                    <video autoPlay loop muted>
                        <source src="${window.location.origin}/assets/video/order-grid.mp4" type="video/mp4"/>
                    </video>
                    `
                }}/>
                <div className="order-bg__content">
                    <div className='order-bg__img'>
                        <img alt="charging-point" src={`${window.location.origin}/assets/img/chargingpoint-3d.png`} />
                    </div>
                    <span>Welcome to grid, Charging Point {chargePoint.name} at {site.siteName}. You're almost there, please enter your details below to begin charging</span>
                </div>
            </div>
            <div className="booking-container max-w-[1000px] mx-auto flex">
                <div className="booking-main">
                    {/* choose park manually */}
                    <div className="booking-main__content p-4 bg-white rounded shadow-md">
                        <div className="booking-main__title text-2xl font-bold pb-4">
                            <span>Order Detail</span>
                        </div>
                        <div className="order-form">
                            <form className='form' onSubmit={e => {e.preventDefault()}}>
                                <div className='form-group'>
                                    <label className='!w-[25%] lg:!w-[50%]' htmlFor="position">Position</label>
                                    <select className='!w-[75%] lg:!w-[50%]  rounded' name="position" id="position" value={positionValue} onChange={onOptionPositionChange}>
                                        <option value={1}>Full Charge</option>
                                        <option value={2}>Fixed Amount (IDR)</option>
                                        <option value={3}>Fixed Amount (KWh)</option>
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label className='!w-[25%] lg:!w-[50%]' htmlFor="amount">Amount</label>
                                    <div  className='w-[75%] lg:w-[50%]'>
                                        <input disabled={disableAmount} className='!w-full' type="text" name='amount' id='amount' placeholder='0' value={amountValue} onChange={onAmountChange} />
                                        <span className='text-slate-500 text-sm block'>Current Rate: Rp. 2475,- / 1kWH</span>
                                    </div>
                                </div>
                                <div className='form-group'>
                                    <label className='!w-[25%] lg:!w-[50%]' htmlFor="connector">Connector</label>
                                    <select className='!w-[75%] lg:!w-[50%]  rounded' name="connector" id="connector" value={connectorValue} onChange={onOptionConnectorChange}>
                                        <option value={1}>32A AC (Blue CEE)</option>
                                        <option value={2}>16A AC (Domestic EU)</option>
                                        <option value={3}>7kW Type 2 AC Charger</option>
                                    </select>
                                </div>
                            </form>
                        </div>
                        <div className="booking-info pb-6">
                            <div className="booking-info__title text-xl pb-2">
                                <span></span>
                            </div>
                            <div className="booking-info__title">
                                <span></span>
                            </div>
                        </div>
                        <div className="booking-custinfo pb-8">
                            <div className="booking-custinfo__title font-bold text-xl pb-4">
                                <span>Customer Detail</span>
                            </div>
                            <div className="booking-custinfo__item">
                                <div className="booking-custinfo__label">
                                    <span>Name</span>
                                </div>
                                <div className="booking-custinfo__val">
                                    <span>{user.username}</span>
                                </div>
                            </div>
                            <div className="booking-custinfo__item">
                                <div className="booking-custinfo__label">
                                    <span>ID</span>
                                </div>
                                <div className="booking-custinfo__val">
                                    <span>#0001232</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* customer detail */}
                </div>
                <div className="booking-summary">
                    <BookSummary type={2} summary={summaryArr} totalExTax={totalNonPpn} total={totalVal} />
                </div>
                 <BookBottom type={2} total={totalVal} summary={summaryArr} totalExTax={totalNonPpn} />
            </div>
        </div>
    )

    return content
}

export default OrderContainer