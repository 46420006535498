import { Document, Page } from 'react-pdf';
import { useState } from 'react';
import { pdfjs } from 'react-pdf';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

const PdfViewerContainer = () => {
    const [numPages, setNumPages] = useState(null);

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }
  return (
    <section>
        <div className='w-full'>
            <Document file="http://localhost:3000/assets/file/brochure.pdf" onLoadSuccess={onDocumentLoadSuccess}>
                {
                    Array(numPages).fill().map((_,i) => (
                        <Page pageNumber={i + 1}  />
                    ))
                }
            </Document>
        </div>
    </section>
  )
}

export default PdfViewerContainer