import Navbar from "../components/Navbar"
import HeroBanner from "../container/HeroBanner"
import LocationContainer from "../container/LocationContainer"
import NumberSection from "../container/NumberSection"
import TextContainer from "../components/TextContainer"
import GroupText from "../container/GroupText"
import BgTextContainer from "../container/BgTextContainer"
import ContactUs from "../container/ContactUs"
import Footer from "../container/Footer"
import Model3DContainer from "../container/Model3DContainer"
import { MapContainer, TileLayer } from "react-leaflet"
import "leaflet/dist/leaflet.css"

const MainPage = ({title}) => {

  // Grouptext content :: begin
  const dataGroupText = [
    {
      title:"A NATIONWIDE NETWORK OF EV ENERGY",
      text:(<>Leveraging a nationwide network of public spaces, commercial and office buildings, you'll find <span className="text-red-700 italic">grid</span> where you want to go, when you need it. Accessible EV energy, nationwide.</>),
      // img:"./assets/img/grouptext_1.webp"
      img:"assets/img/1.jpg"
    },
    {
      title:"REAL-TIME INFORMATION",
      // text:"Complete control while you move, grid allows you to monitor your EV charging without the need to stay with your vehicle. Find available grid charging points, view your charging progress, schedule your energy use, be notified on completion, and know of any problems, instantly.",
      text:(<>Complete control while you move, <span className="text-red-700 italic">grid</span> allows you to monitor your EV charging without the need to stay with your vehicle. Find available <span className="text-red-700 italic">grid</span> charging points, view your charging progress, schedule your energy use, be notified on completion, and know of any problems, instantly.</>),
      imgList:[
        "assets/img/app1.png",
        "assets/img/app2.png",
        "assets/img/app3.png",
        "assets/img/app4.png",
      ]
      ,
    },
    {
      title:"ACCESIBLE - FLEXIBLE - AVAILABLE",
      // text:"We aim to be at your school, your office, your shopping centers and your public spaces. By providing EV energy access through both the Indonesian Type 2 Standard and a BYOC Interface, s is fully inclusive and futureproof. ",
      text:(<>We aim to be at your school, your office, your shopping centers and your public spaces. By providing EV energy access through both the Indonesian Type 2 Standard and a BYOC Interface, <span className="text-red-700 italic">grid</span> is fully inclusive and futureproof.</>),
      // img:"./assets/img/accessible_flexible_available.png"
      imgList:[
        "assets/img/pic1.png",
        "assets/img/pic2.png",
        "assets/img/pic3.png",
        "assets/img/pic4.png",
        "assets/img/pic-grid.png",
        "assets/img/grid_view.png",
      ]
    },
  ]

  const groupTextTitle = "EV Charging"
  const groupTextSubtitle = "Tailored for the Indonesian Lifestyle"
  const groupTextHeading = "What We Do"
  // Grouptext content :: end

  // bgtextcomtainer content :: begin
  const bgtcHeading = (
    <>
      <span>JOIN THE <span className="text-red-700 italic">grid </span>NETWORK</span>
    </>
  )
  const bgtcTitle = 'A NEW PURPOSE FOR YOUR EXCESS ENERGY'

  const bgtcText = (
    <>
      <p>Owning, running, or operating a public space, hotel, office complex or commercial shopping areas will guarantee you access to unusable excess energy. Let us help you convert that energy into a new source of revenue. Improving your bottom line without interrupting your daily operations.</p>
      <p>
      <span className="text-red-700 italic">grid</span> works with you. This includes giving you full technical analytics, operational access, and full strategies to ensure that unlike conventional EV charging stations, you achieve an ROI in months instead of decades.
      </p>
    </>
  )

  const bgtcBg = './assets/img/bgtc_bg.jpg'
  // bgtextcomtainer content :: end

  document.title = title

  return (
    <>
        <Navbar/>
        <HeroBanner/>
        <LocationContainer parentToggle={false} />
        <NumberSection />
        <TextContainer title={"Accessible Charging Network Tailored for Indonesia"} text={(<><p>Charging your EV should neither be an event nor a destination. It should be a natural progression, a normal activity, an afterthought. An extension of your daily movement that does not require its own special consideration.</p><p>Here at <span className="text-red-700 italic">grid</span>, we aim to provide charging points where you are, exactly where you want them to be. An accessible nationwide charging network that comes to you, instead of the other way around.</p></>)} img={"./assets/img/textcontainer_1.webp"} />
        <GroupText key={groupTextTitle.concat(groupTextSubtitle)} title={groupTextTitle} subtitle={groupTextSubtitle} heading={groupTextHeading} data={dataGroupText}/>
        <BgTextContainer key={bgtcBg.concat(bgtcTitle)} title={bgtcTitle} heading={bgtcHeading} text={bgtcText} bg={bgtcBg} />
        <TextContainer title={"Our Technology"} text={(<><p><span className="text-red-700 italic">grid</span> is an ecosystem of devices, each tailored to ensure complete integration on each site. unlike conventional EV Charging Stations, <span className="text-red-700 italic">grid</span> does not simply tap into the electrical energy on site, it adjusts itself continuously when needed according to the amount of available energy, ensuring that your business remains a priority.</p></>)} heading="How We Do It" comp={(<img src="./assets/img/equipments.png"/>)} />

        <ContactUs/>
        <Footer/>
    </>
  )
}

export default MainPage