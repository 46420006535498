import Navbar from "../components/Navbar"
import ApplyForm from "../components/ApplyForm"
import ContactUs from "../container/ContactUs"
import Footer from "../container/Footer"
import "leaflet/dist/leaflet.css"

const Apply = ({title}) => {

  document.title = title

  return (
    <>
        <Navbar mainPage={0}/>
        <ApplyForm />
        <ContactUs/>
        <Footer/>
    </>
  )
}

export default Apply