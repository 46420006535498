import { Swiper, SwiperSlide } from "swiper/react"


import "swiper/css";
import "swiper/css/effect-coverflow";

import { EffectCoverflow,Autoplay } from "swiper";


const ImgCarousel = (imgList) => {

  console.log(imgList)

  const swiperSlide = imgList?.length > 0 ? imgList.map((itm,id) => <SwiperSlide><img src={itm} /></SwiperSlide>) : 'no image'

  console.log(swiperSlide)
  return (
    <>
      {
        imgList.imgList?.length > 0 ? 
        (
          <Swiper
            effect={"coverflow"}
            grabCursor={true}
            loop={true}
            centeredSlides={true}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            slidesPerView={"auto"}
            coverflowEffect={{
              rotate: 30,
              stretch: 80,
              depth: 100,
              modifier: 1,
              scale:0.8,
              slideShadows: true,
            }}
            modules={[EffectCoverflow,Autoplay]}
            className="mySwiper"
          >
            {
               imgList.imgList?.map((itm,id) => <SwiperSlide><img src={itm} /></SwiperSlide>)
            }
            {/* <SwiperSlide>
              <img src="assets/img/grouptext_2.jpg" />
            </SwiperSlide>
            <SwiperSlide>
              <img src="assets/img/grouptext_3.webp" />
            </SwiperSlide>
            <SwiperSlide>
              <img src="assets/img/grouptext_2.jpg" />
            </SwiperSlide>
            <SwiperSlide>
              <img src="assets/img/grouptext_4.jpg" />
            </SwiperSlide> */}
          </Swiper>
        ) : ''
      }
    </>
  )
}

export default ImgCarousel