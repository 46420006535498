import React, { useRef, useState, Suspense } from "react";
import {
    Engine,
    Model,
    Scene,
    useBeforeRender,
    useClick,
    useHover
} from "react-babylonjs";
  
import { Vector3 } from "@babylonjs/core/Maths/math.vector";
import { Color3, Color4 } from "@babylonjs/core/Maths/math.color";
import "@babylonjs/loaders/glTF" 
  


const Model3DContainer = () => {

    const content = (
        <>
            <Engine antialias adaptToDeviceRatio canvasId="babylon-canvas">
                <Scene
                    clearColor={new Color4(0, 0, 0, 1.0)}
                    onSceneMount={({scene}) => console.log('clear color from scene:', scene.clearColor)}
                >
                    
                    <arcRotateCamera name="camera1"
                    alpha={Math.PI / 4} beta={Math.PI / 4}
                    radius={0.5} target={Vector3.Zero()} minZ={0.001} upperRadiusLimit={0.6} lowerRadiusLimit={0.48} />
                    
                    <hemisphericLight name="light1" intensity={0.7}
                    direction={Vector3.Up()} />
                    <Suspense fallback={<box name="fallback" position={Vector3.Zero()}></box>}>
                        <Model sceneFilename="v4.glb"
                            rootUrl = {`./assets/model/`}
                            position = { new Vector3(0, 0, 0) }
                        />
                    </Suspense>
                </Scene>
            </Engine>
        </>
    )
    return content
}

export default Model3DContainer