
const BookSummary = ({type,summary,total,totalExTax}) => {

    const onProcessClick = () => {
        if (type == 1)
            window.location.replace(`https://apigrid.bireka.id/booking?harga=${totalExTax}`)
        else if (type == 2)
            window.location.replace(`https://apigrid.bireka.id/payment?harga=${totalExTax}`)
    }

    const bookSummary = summary.map(sum => {
        return (
            <div key={sum.label} className="booking-summary__detail-wrapper">
                <div className="booking-summary__detail-label">
                    <span>{sum.label}</span>
                </div>
                <div className="booking-summary__detail-val">
                    <span>{sum.id === '3' ? `${sum.val} KwH` : `Rp. ${sum.val},-`}</span>
                </div>
            </div>
        )
    })
    const content = (
        <div className="p-4 bg-white rounded shadow-lg">
            <div className="booking-summary__detail">
                <div className="booking-summary__detail-title">
                    <span>Booking Summary</span>
                </div>
                <div className="booking-summary__detail-info">
                    {bookSummary}
                </div>
                <div className="booking-summary__detail-total">
                    <div className="booking-summary__detail-label">
                        <span>Total</span>
                    </div>
                    <div className="booking-summary__detail-val">
                        <span>Rp. {total},-</span>
                    </div>
                </div>
            </div>
            <div className="booking-summary__process flex">
                <button className="button w-full" onClick={onProcessClick}>Process</button>
            </div>
        </div>
    )
    
    return content
}

export default BookSummary