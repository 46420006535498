import React from 'react'
import  axios  from "axios"
import { useState } from 'react'
import { Box,TextField, Button, Modal } from '@mui/material'
import { MuiFileInput } from 'mui-file-input'
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useNavigate } from 'react-router-dom'

import { MapContainer,TileLayer ,Marker,Popup} from "react-leaflet"
import 'leaflet/dist/leaflet.css';
import L from 'leaflet'
import { redirect } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClose } from '@fortawesome/free-solid-svg-icons'

const ApplyForm = () => {

    const navigate = useNavigate()
    const coord = [-8.619241956458541, 115.14861788170886]

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        // border: '2px solid #000',
        boxShadow: 24,
        borderRadius:'.2rem',
        p: 4,
      };

    const mapContent = (
        <>
            <MapContainer id="map2" center={coord} zoom={17} scrollWheelZoom={false}>
                <TileLayer
                    attirbution="openstreetmap"
                    url = 'https://mt0.google.com/vt/lyrs=m&x={x}&y={y}&z={z}'
                />
            </MapContainer>
        </>
    )

    const [open,setOpen] = useState(false)
    const [openUploading,setOpenUploading] = useState(false)
    const handleOpenModal = () => {
        setOpen(true)
    }
    
    
    
    const handleCloseModal = () => {
        setOpen(false)
    }
    
    const handleCloseModalUploading = () => {
        // setOpenUploading(false)
        console.log('please wait until uploading done...')
    }


    const [locationName,setLocationName] = useState('')
    // const [modalInfo,setModalInfo] = useState('')
    const locationNameOnChange = (e) => {
        setLocationName(e.target.value)
    }
        
    const [locationType,setLocationType] = useState('')
    const locationTypeOnChange = (e) => {
        setLocationType(e.target.value)
    }
    
    const [distance,setDistance] = useState('')
    const distanceOnChange = (e) => {
        setDistance(e.target.value)
    }
    
    const [parkingType,setParkingType] = useState('')
    const parkingTypeOnChange = (e) => {
        setParkingType(e.target.value)
    }

    const [locationAddress,setLocationAddress] = useState('')
    const locationAddressOnChange = (e) => {
        setLocationAddress(e.target.value)
    }
    
    const [carParkingCapacity,setCarParkingCapacity] = useState('')
    const carParkingCapacityOnChange = (e) => {
        setCarParkingCapacity(e.target.value)
    }
    
    const [buildingPowerCapacity,setBuildingPowerCapacity] = useState('')
    const buildingPowerCapacityOnChange = (e) => {
        setBuildingPowerCapacity(e.target.value)
    }
    
    const [locationCoordinate,setLocationCoordinate] = useState('')
    const locationCoordinateOnChange = (e) => {
        setLocationCoordinate(e.target.value)
    }
    
    const [picName,setPicName] = useState('')
    const picNameOnChange = (e) => {
        setPicName(e.target.value)
    }
    
    const [picJobTitle,setPicJobTitle] = useState('')
    const picJobTitleOnChange = (e) => {
        setPicJobTitle(e.target.value)
    }
    
    const [picEmail,setPicEmail] = useState('')
    const picEmailOnChange = (e) => {
        setPicEmail(e.target.value)
    }
    
    
    const [parkingAreaFile,setParkingAreaparkingAreaFile] = useState(null)
    const parkingAreaFileOnChange = (newValue,info) => {
        setParkingAreaparkingAreaFile(newValue)
    }
    // const parkingAreaFileOnChange = (e) => {
    //     setParkingAreaparkingAreaFile(e.target.files)
    // }
    
    const [electricalPanelFile,setParkingAreaelectricalPanelFile] = useState(null)
    const electricalPanelFileOnChange = (newValue,info) => {
        setParkingAreaelectricalPanelFile(newValue)
    }

    const modalErr = (
        <Modal
            open={open}
            onClose={handleCloseModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style} >
                <div>
                    <span>
                        All Data Is Required
                    </span>
                </div>
            </Box>
            
        </Modal>
    )

    const modalUploading = (
        <Modal
            open={openUploading}
            onClose={handleCloseModalUploading}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style} >
                <span>
                    Uploading Data.. Please wait
                </span>
            </Box>
            
        </Modal>
    )
    
    const [picPhoneNo,setPicPhoneNo] = useState('')
    const picPhoneNoOnChange = (e) => {
        setPicPhoneNo(e.target.value)
    }

    const [cnufs,setCnufs] = useState('hidden')

    const uploadFormSection = (
        <div className={cnufs}>
            Hello
            <div>
                {locationName}
            </div>
            <div>
                {locationType}
            </div>
            <div>
                {locationAddress}
            </div>
            <div>
                {carParkingCapacity}
            </div>
            <div>
                {parkingType}
            </div>
            <div>
                {buildingPowerCapacity}
            </div>
            <div>
                {locationCoordinate}
            </div>
            <div>
                {picName}
            </div>
            <div>
                {picJobTitle}
            </div>
            <div>
                {picEmail}
            </div>
            <div>
                {picPhoneNo}
            </div>
            <div>

            </div>
        </div>
    )
    
    const submitHandler = async () => {
        
        console.log('submit is clicked')


        // cek kelengkapan data

        if (locationName === '' || locationType === '' || locationAddress === '' || carParkingCapacity === '' || parkingType === '' || buildingPowerCapacity === '' || locationCoordinate === '' || picName === '' || picJobTitle === '' || picEmail === '' || picPhoneNo === '' || distance === '')
        {
            setOpen(true)
            return
        }


        let bodyFormData = new FormData();
        let subject = `New Applied Client - ${locationName}`
        let body = 
            `
                <p>Location Name ${locationName}</p>
                </br>
                <p>Location Type ${locationType} </br></p>
                <p>Location Address ${locationAddress}</br></p>
                <p>Car Parking Capacity ${carParkingCapacity}</br></p>
                <p>Parking Type ${parkingType}</br></p>
                <p>Building Power Capacity ${buildingPowerCapacity}</br></p>
                <p>Location Coordinate ${locationCoordinate}</br></p>
                <p></p>
                
                <p>--------------------------------------------</p>
                <p>PIC Name ${picName}</br></p>
                <p>PIC Job Title ${picJobTitle}</br></p>
                <p>PIC Email ${picEmail}</br></p>
                <p>PIC Phone No ${picPhoneNo}</br></p>
                <p></p>
                <p>--------------------------------------------</br></p>
                <p>Distance From Electrical Panel to Parking Lot ${distance}</br></p>
                <p></p>
                <p>File Attached</br></p>
            `

        bodyFormData.append('subject',subject)
        bodyFormData.append('body',body)
        // bodyFormData.append('parkingarea',parkingAreaFile)
        // bodyFormData.append('electricalPanel',electricalPanelFile)

        for (let i = 0; i < parkingAreaFile.length; i++)
        {
            bodyFormData.append('parkingarea[]',parkingAreaFile[i])
        }
        
        for (let i = 0; i < electricalPanelFile.length; i++)
        {
            bodyFormData.append('electricalpanel[]',electricalPanelFile[i])
        }

        // setCnufs('block')

        setOpenUploading(true)

        axios({
            method: "post",
            url: "https://apigrid.bireka.id/api/sendemail",
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
          })
        .then(function (response) {
            console.log(response);
            setOpenUploading(false)
            navigate('/')
            return
        })
        .catch(function (response) {
            //handle error
            setOpenUploading(false)
            console.log(response);
        });

    }

  return (
    <section className='px-4 block min-h-[calc(100vh_-_164px)]'>
        {modalErr}
        {modalUploading}
        <div className='mt-4 mx-auto max-w-[1200px] bg-white'>
            <div className='pt-4 pb-8'>
                <span className='font-semibold text-2xl lg:text-3xl'>
                    Survey Application Form
                </span>
            </div>
            <div className='flex flex-wrap pb-8'>
                <div className='w-full pb-4 pt-2'>
                    <span className='text-xl text-red-600 font-semibold'>
                        Location Information
                    </span>
                </div>
                <div className='w-full md:w-[50%] pb-4 px-2'>
                    <TextField className='w-full' id="outlined-basic" label="Location Name" variant="outlined" value={locationName} onChange={locationNameOnChange}/>
                </div>
                <div className='w-full md:w-[50%] pb-4 px-2'>
                    {/* <TextField className='w-full' id="outlined-basic" label="Location Type" variant="outlined" /> */}
                    <FormControl variant='standard' fullWidth>
                        <InputLabel id="demo-simple-select-label">Location Type</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={locationType}
                            label="Age"
                            onChange={locationTypeOnChange}
                        >
                            <MenuItem value={'office'}>Perkantoran</MenuItem>
                            <MenuItem value={'shoppingcenter'}>Perbelanjaan</MenuItem>
                            <MenuItem value={'residence'}>Residensial</MenuItem>
                        </Select>
                    </FormControl>
                </div>
                <div className='w-full md:w-[50%] pb-4 px-2'>
                    <TextField className='w-full' id="outlined-basic" label="Location Address" variant="outlined" value={locationAddress} onChange={locationAddressOnChange}/>
                </div>
                <div className='w-full md:w-[50%] pb-4 px-2'>
                    <TextField className='w-full' id="outlined-basic" label="Car Parking Capacity" variant="outlined" value={carParkingCapacity} onChange={carParkingCapacityOnChange}/>
                </div>
                <div className='w-full md:w-[50%] pb-4 px-2'>
                    {/* <TextField className='w-full' id="outlined-basic" label="Type" variant="outlined" /> */}
                    <FormControl variant='standard' fullWidth>
                        <InputLabel id="demo-simple-select-label">Parking Type</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={parkingType}
                            label="Age"
                            onChange={parkingTypeOnChange}
                        >
                            <MenuItem value={'indoor'}>Indoor</MenuItem>
                            <MenuItem value={'outdoor'}>Outdoor</MenuItem>
                            <MenuItem value={'combined'}>Combined</MenuItem>
                        </Select>
                    </FormControl>
                </div>
                <div className='w-full md:w-[50%] pb-4 px-2'>
                    <TextField className='w-full' id="outlined-basic" label="Building Power Capacity" variant="outlined" value={buildingPowerCapacity} onChange={buildingPowerCapacityOnChange}/>
                </div>
                <div className='w-full md:w-[50%] pb-4 px-2'>
                    <TextField className='w-full' id="outlined-basic" label="Location Coordinate" variant="outlined" value={locationCoordinate} onChange={locationCoordinateOnChange}/>
                    {/* <div>
                        <Button variant="text" onClick={handleOpenModal}>Pick From Map</Button>
                        <Modal
                            open={open}
                            onClose={handleCloseModal}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >
                            <Box sx={style} >
                                <div className='apply-map'>

                                    {mapContent}
                                </div>
                            </Box>
                            
                        </Modal>
                    </div> */}
                </div>
            </div>
            <div className='flex flex-wrap pb-8'>
                <div className='w-full pb-4 pt-2'>
                    <span className='text-xl text-red-600 font-semibold'>
                        PIC
                    </span>
                </div>
                <div className='w-full md:w-[50%] pb-4 px-2'>
                    <TextField className='w-full' id="outlined-basic" label="PIC Name" variant="outlined" value={picName} onChange={picNameOnChange}/>
                </div>
                <div className='w-full md:w-[50%] pb-4 px-2'>
                    <TextField className='w-full' id="outlined-basic" label="PIC Job Title" variant="outlined" value={picJobTitle} onChange={picJobTitleOnChange}/>
                </div>
                <div className='w-full md:w-[50%] pb-4 px-2'>
                    <TextField className='w-full' id="outlined-basic" label="PIC Email" variant="outlined" value={picEmail} onChange={picEmailOnChange}/>
                </div>
                <div className='w-full md:w-[50%] pb-4 px-2'>
                    <TextField className='w-full' id="outlined-basic" label="PIC Phone No." variant="outlined" value={picPhoneNo} onChange={picPhoneNoOnChange}/>
                </div>
            </div>
            <div className='flex flex-wrap pb-8'>
                <div className='w-full pb-4 pt-2'>
                    <span className='text-xl text-red-600 font-semibold'>
                        Layout Information
                    </span>
                </div>
                <div className='w-full md:w-[50%] pb-4 px-2'>
                    <MuiFileInput className='w-full' variant='outlined' label="Parking Area Images" multiple name='parkingarea' value={parkingAreaFile} onChange={parkingAreaFileOnChange} helperText={'Max 2MB per file'}/>
                </div>
                <div className='w-full md:w-[50%] pb-4 px-2'>
                    <MuiFileInput className='w-full' variant='outlined' label="Electrical Panel Images" multiple name='electricalpanel' value={electricalPanelFile} onChange={electricalPanelFileOnChange}  helperText={'Max 2MB per file'}/>
                </div>
                <div className='w-full md:w-[50%] pb-4 px-2'>
                    <TextField className='w-full' id="outlined-basic" label="Distance From Electrical Panel to Parking Lot" variant="outlined"  value={distance} onChange={distanceOnChange}/>
                </div>
            </div>
            <div className='flex justify-end pb-8'>
                <button type='submit' className='button button-primary' onClick={submitHandler}>Submit</button>
            </div>
        </div>
        <div>
            {uploadFormSection}
        </div>
    </section>
  )
}

export default ApplyForm