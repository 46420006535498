import { useState,useEffect } from "react"
import { MapContainer,TileLayer ,Marker,Popup} from "react-leaflet"
import 'leaflet/dist/leaflet.css';
import L from 'leaflet'

import markerIcon from 'leaflet/dist/images/marker-icon.png'
import ContactUsForm from "../components/ContactUsForm";

const ContactUs = () => {

    const marker = new L.icon({
        iconUrl:markerIcon,
    })

    const coord = [-8.619241956458541, 115.14861788170886]

    const mapContent = (
        <>
            <MapContainer id="map2" center={coord} zoom={17} scrollWheelZoom={false}>
                <TileLayer
                    attirbution="openstreetmap"
                    url = 'https://mt0.google.com/vt/lyrs=m&x={x}&y={y}&z={z}'
                />
                <Marker
                    position={coord}
                    icon={marker}
                >
                </Marker>
            </MapContainer>
        </>
    )

    const content = (
        <>
            <div className="contactus bg-center bg-cover bg-fixed" style={{backgroundImage:`linear-gradient(rgba(40, 40, 40, 0.9), rgba(40, 40, 40, 0.9)),url(${process.env.PUBLIC_URL+ "/assets/img/contactus_bg.jpg"})`}}>
                <div className="contactus-container">
                    <div className="contactus-wrapper">
                        <ContactUsForm />
                    </div>
                    <div className="contactus-map">
                        {mapContent}
                        <div className="contactus-info">
                            <div className="contactus-info__wrapper">
                                <div className="contactus-info__content">
                                    <span>PT. Bhinneka Rekayasa Teknologi</span>
                                </div>
                                <div className="contactus-info__content">
                                    <span>Merak 5, Jl. Veteran, Mengwi, Kab. Badung</span>
                                </div>
                                <div className="contactus-info__content">
                                    <span>Bali, Indonesia 80351</span>
                                </div>
                                <div className="contactus-info__content">
                                    <a className="font-bold text-red-700" href="https://bireka.id"><span>https://bireka.id</span></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
    return content  
}

export default ContactUs