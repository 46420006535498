import CountUp from "react-countup"
import { useInView } from "react-intersection-observer"


const NumberSectionCard = ({number,text,symbol}) => {

    const [ref,inView] = useInView({
        threshold:0.3,
        triggerOnce:true
    })

    const content = (
        <div className="numbersection-card" ref={ref}>
            <div className="numbersection-card__number">
                <span>
                    <CountUp end={inView ? number : 0}>
                    </CountUp>{symbol}
                </span>                
            </div>
            <div className="numbersection-card__div">
                <div className="boldline"></div>
            </div>
            <div className="numbersection-card__text">
                <span>{text}</span>
            </div>
        </div>
    )
  return content
}

export default NumberSectionCard