import {useState, useEffect} from 'react'

const BookManual = ({manualPick,setManualPick}) => {

    const [pickClass,setPickClass] = useState('hidden')

    const onChooseManualChanged = () => {
        setManualPick(prev => !prev)
    }

    useEffect (() => {
        if(manualPick)
        {
            setPickClass('opacity-100')
        }
        else{
            setPickClass('hidden opacity-0')
        }
    },[manualPick])


    const parkSlots = [
        {
            id:"1",
            name:"A1 (22 KW Max)"
        },
        {
            id:"2",
            name:"A2 (7 KW)"
        },
        {
            id:"3",
            name:"A3 (7 KW)"
        },
        {
            id:"4",
            name:"A4 (7 KW)"
        },
    ]

    const optionsParkSlots = parkSlots.map(parkSlot => (<option key={parkSlot.id} value={parkSlot.id} >{parkSlot.name}</option>))

    const content = (
        <>
            <div className="booking-choosemanual">
                <div className="booking-choosemanual__check flex pb-6">
                    <div className="booking-choosemanual__input pr-2">
                        <input type="checkbox" name="choosemanual" id="choosemanual" checked={manualPick} onChange={onChooseManualChanged} />
                    </div>
                    <div className="booking-choosemanual__label">
                        <label htmlFor="">I want to pick my space</label>
                        <span className="block text-sm">You will be charged extra for picking your space manually</span>
                    </div>
                </div>
                <div className={`booking-choosemanual__pick ${pickClass} transition-all duration-300 ease-in-out pb-6`}>
                    <div className="booking-choosemanual__map">
                        <img src={`${window.location.origin}/assets/img/parking-layout.jpg`} alt="parking-layout" />
                    </div>
                    <div className="booking-choosemanual__form">
                        <form onSubmit={e => e.preventDefault()}>
                            <div className="form-group">
                                <label className="" htmlFor="parkingSlot">
                                    Pick Space
                                </label>
                                <select className="" id="parkingSlot" name="parkingSlot" >
                                    {optionsParkSlots}
                                </select>
                            </div>
                        </form>
                    </div>
                </div>
                <div className='booking-choosemanual__'>

                </div>
            </div>
        </>
    )
    return content
}

export default BookManual