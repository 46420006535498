import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileInvoice,faDownload } from '@fortawesome/free-solid-svg-icons'
import React from 'react'

const TechnologyItem = ({title,downloadFile,id,setActive}) => {
    
    
    const handleDownload = (pdfFile) => {
        fetch(`assets/file/${pdfFile}`).then(response => {
            response.blob().then(blob => {
                const fileURL = window.URL.createObjectURL(blob)

                let alink = document.createElement('a')
                alink.href = fileURL
                alink.download = pdfFile
                alink.click()
            })
        })
    }


  return (
    // <div className='w-full md:w-[45%] lg:w-[30%] md:mx-2 pb-2 border-b border-b-black border-solid flex justify-between mb-8'>
    <div className='w-full md:mx-2 pb-2 border-b border-b-black border-solid flex justify-between mb-8'>
        <div className='w-full'>
            <div className='text-[18px] md:text-[18px] pb-3'>
                <h4>
                    <span className='text-[24px] text-red-700 pr-2'>
                        <FontAwesomeIcon icon={faFileInvoice} />
                    </span>
                    {title}
                </h4>
            </div>
            <div className='flex items-center justify-between'>
                <div className='py-2 px-2 border border-red-700 border-solid rounded-lg mr-4 text-[14px]'>
                    PDF
                </div>
                <div className=''>
                    {/* <button className='py-2 px-4 bg-red-700 text-white' onClick={() => {handleDownload(downloadFile)}}>Download</button> */}
                    <button className='py-2 px-4 mr-2 bg-red-700 text-white' onClick={() => {setActive(id)}}>View</button>
                    <button className='py-2 px-4 bg-red-700 text-white' onClick={() => {handleDownload(downloadFile)}}><FontAwesomeIcon icon={faDownload} /></button>
                </div>
            </div>
        </div>
    </div>
  )
}

export default TechnologyItem