import { faAngleRight } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import ImgCarousel from "./ImgCarousel"

const GroupTextItem = ({title,text, img, imgList}) => {

    const secondComp = img ? (<><img src={img}  /></>) : (<ImgCarousel imgList={imgList}/>)
    const content = (
    <>
        <div className="grouptext-item">
            <div className="grouptext-item__content">
                <div className="pl-20">
                    <div className="grouptext-item__title">
                        <h2>
                            {title}
                        </h2>
                    </div>
                    <div className="grouptext-item__text">
                        <span>
                            {text}
                        </span>
                    </div>
                    {/* <div className="">
                        <button type="button" className="flex justify-center">
                            <div className="btn_readmore btn_readmore-light leading-[1.4]">Read More</div>
                            <div className="inline-flex h-full border border-solid border-gray-500 pt-3 pb-[11px] px-4 text-gray-700 rounded-tr rounded-br">
                                <FontAwesomeIcon icon={faAngleRight} />
                            </div>
                        </button>
                    </div> */}
                </div>
            </div>
            <div className="grouptext-item__img">
                {secondComp}
            </div>
        </div>
    </>
    )
    return content
}

export default GroupTextItem