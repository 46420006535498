import Navbar from '../components/Navbar'
import Footer from '../container/Footer'
import PdfViewerContainer from '../container/PdfViewerContainer'


const Pdfviewer = () => {
  return (
    <>
        <Navbar mainPage={0}/>
        <PdfViewerContainer />
        <Footer/>
    </>
  )
}

export default Pdfviewer