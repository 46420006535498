import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../provider/AuthProvider';

const MenuContainer = ({isLogin,active,onCloseClick}) => {
        
    const additionalClass = active ? "show" : "";

    const navigate = useNavigate()

    const {logout} = useAuth()

    const handleLoginClick = () => {
        // navigate('https://gridmobility.id')
    }
    
    const handleLogoutClick = () => {
        logout()
    }

    const avatar = isLogin ? 
        (
            <>
                <a href='https://gridmobility.id' className='button button-primary' onClick={handleLoginClick} >Go To App</a>
            </>
        )
        :
        (
            <>
                <a href='https://gridmobility.id' className='button button-primary' onClick={handleLoginClick} >Go To App</a>
            </>
        )
    
    return (
        <div className={`navbar-menu_container ${additionalClass}`} >
                <div className={`closemobile`} onClick={onCloseClick}>
                    <span className='text-4xl'>
                        <FontAwesomeIcon icon={faClose}/>
                    </span>
                </div>
                <div className='navbar-menu__item' onClick={() => {navigate('/')}}>
                    <span>Home</span>
                </div>
                <div className='navbar-menu__item' onClick={() => {navigate('/technology')}}>
                    <span>Technology</span>
                </div>
                {/* <div className='navbar-menu__item'>
                    <span>About</span>
                </div> */}
                <div className='navbar-menu__item' onClick={() => {navigate('/apply')}}>
                    <button className='button button-primary' >Apply</button>
                </div>
                <div className='navbar-menu__item'>
                    {avatar}
                </div>
            </div>
    )
}

export default MenuContainer