import "leaflet/dist/leaflet.css"
import {useEffect,useState,useRef} from 'react'
import L from 'leaflet'

const SliderControl = ({maxTotal , setMaxTotal}) => {
    const ref = useRef(null)

    const [rangeSliderVal,setRangeSliderVal] = useState(6)

    const [rangeSliderLabel,setRangeSliderLabel] = useState(rangeSliderVal)


    const sliderValChangeHandler = (e) => {
      setRangeSliderVal(e.target.value)
    }

    const sliderMouseUpHandler = (e) => {
        setMaxTotal(e.target.value)
    }


    useEffect(() => {
      setRangeSliderLabel(rangeSliderVal)
    },[rangeSliderVal])

    useEffect(() => {
      L.DomEvent.disableClickPropagation(ref.current)
      L.DomEvent.disableScrollPropagation(ref.current)
    },[])

    return (
      <div ref={ref} className="flex flex-col justify-center">
        <div className="flex justify-center">
          <input value={rangeSliderVal} type="range" orient="vertical" className="w-[30px] h-[300px]" onChange={sliderValChangeHandler} onMouseUp={sliderMouseUpHandler} onTouchEnd={sliderMouseUpHandler} min={1} max={20}/>
        </div>
        <div className="flex justify-center">
          <label className="text-[20px] font-bold text-center [-webkit-text-stroke-width:1px] [-webkit-text-stroke-color:white]  text-red-800">{rangeSliderLabel}</label>
        </div>
      </div>
    )
  }


export default SliderControl