
const HeroBanner = () => {
  return (
    <>
        <div className="herobanner">
          <div className="herobanner__container" >
            <div  dangerouslySetInnerHTML={{
              __html:
              `
              <video autoPlay loop muted>
                <source src="./assets/video/wix-grid.mp4" type="video/mp4"/>
              </video>
              `
            }}/>
            <div className="herobanner__content">
              <h1 className="">WORRY-FREE EV MOBILITY</h1>
              <p>Discover Indonesia's Most Accessible EV Charging Network</p>
            </div>
          </div>
        </div>
    </>
  )
}

export default HeroBanner