export const SITES = [
    {
      siteId:"00001",
      siteName:"Tamora Square",
      siteAddress:"Jl. Subak Sari 13, Tibubeneng, Kec. Kuta Utara, Kabupaten Badung, Bali 80361",
      siteDescription:"Trial run 2023",
      numberOfTotalChargers:9,
      numberOfAvailableChargers:0,
      coordinates:[-8.664532298296962, 115.14723817779938],
      type:1,
      reservation:true,
      chargingPoint:[
        {
          id:1,
          name:"A1",
          number:"000001",
          connector:[
            {name:"CEE 7/4 16A SCHUTZKONTAKT"},
            {name:"IEC 60309 32A 2P+E"},
            {name:"IEC 62196 TYPE 2 (Mennekes)"},
          ],
        },
        {
          id:2,
          name:"A2",
          number:"000002",
          connector:[
            {name:"CEE 7/4 16A SCHUTZKONTAKT"},
            {name:"IEC 60309 32A 2P+E"},
            {name:"IEC 62196 TYPE 2 (Mennekes)"},
          ],
        },
        {
          id:3,
          name:"A3",
          number:"000003",
          connector:[
            {name:"CEE 7/4 16A SCHUTZKONTAKT"},
            {name:"IEC 60309 32A 2P+E"},
            {name:"IEC 62196 TYPE 2 (Mennekes)"},
          ],
        },
        {
          id:4,
          name:"A4",
          number:"000004",
          connector:[
            {name:"CEE 7/4 16A SCHUTZKONTAKT"},
            {name:"IEC 60309 32A 2P+E"},
            {name:"IEC 62196 TYPE 2 (Mennekes)"},
          ],
        },
        {
          id:5,
          name:"A5",
          number:"000005",
          connector:[
            {name:"CEE 7/4 16A SCHUTZKONTAKT"},
            {name:"IEC 60309 32A 2P+E"},
            {name:"IEC 62196 TYPE 2 (Mennekes)"},
          ],
        },
        {
          id:6,
          name:"A6",
          number:"000006",
          connector:[
            {name:"CEE 7/4 16A SCHUTZKONTAKT"},
            {name:"IEC 60309 32A 2P+E"},
            {name:"IEC 62196 TYPE 2 (Mennekes)"},
          ],
        },
        {
          id:7,
          name:"A7",
          number:"000007",
          connector:[
            {name:"CEE 7/4 16A SCHUTZKONTAKT"},
            {name:"IEC 60309 32A 2P+E"},
            {name:"IEC 62196 TYPE 2 (Mennekes)"},
          ],
        },
        {
          id:8,
          name:"A8",
          number:"000008",
          connector:[
            {name:"CEE 7/4 16A SCHUTZKONTAKT"},
            {name:"IEC 60309 32A 2P+E"},
            {name:"IEC 62196 TYPE 2 (Mennekes)"},
          ],
        },
        {
          id:9,
          name:"A9",
          number:"000009",
          connector:[
            {name:"CEE 7/4 16A SCHUTZKONTAKT"},
            {name:"IEC 60309 32A 2P+E"},
            {name:"IEC 62196 TYPE 2 (Mennekes)"},
          ],
        },
      ],
    },
    {
      siteId:"00002",
      siteName:"The Tamora",
      siteAddress:"Jl. Pantai Berawa No.99, Tibubeneng, Kec. Kuta Utara, Kabupaten Badung, Bali 80361",
      siteDescription:"Trial run 2023",
      numberOfTotalChargers:6,
      numberOfAvailableChargers:0,
      coordinates:[-8.660621950400623, 115.13895664024285],
      type:1,
      reservation:true,
      chargingPoint:[
        {
          id:1,
          name:"A1",
          number:"000001",
          occupied:false,
          connector:[
            {name:"CEE 7/4 16A SCHUTZKONTAKT"},
            {name:"IEC 60309 32A 2P+E"},
            {name:"IEC 62196 TYPE 2 (Mennekes)"},
          ],
        },
        {
          id:2,
          name:"A2",
          number:"000002",
          occupied:false,
          connector:[
            {name:"CEE 7/4 16A SCHUTZKONTAKT"},
            {name:"IEC 60309 32A 2P+E"},
            {name:"IEC 62196 TYPE 2 (Mennekes)"},
          ],
        },
        {
          id:3,
          name:"A3",
          number:"000003",
          connector:[
            {name:"CEE 7/4 16A SCHUTZKONTAKT"},
            {name:"IEC 60309 32A 2P+E"},
            {name:"IEC 62196 TYPE 2 (Mennekes)"},
          ],
        },
        {
          id:4,
          name:"A4",
          number:"000004",
          connector:[
            {name:"CEE 7/4 16A SCHUTZKONTAKT"},
            {name:"IEC 60309 32A 2P+E"},
            {name:"IEC 62196 TYPE 2 (Mennekes)"},
          ],
        },
        {
          id:5,
          name:"A5",
          number:"000005",
          connector:[
            {name:"CEE 7/4 16A SCHUTZKONTAKT"},
            {name:"IEC 60309 32A 2P+E"},
            {name:"IEC 62196 TYPE 2 (Mennekes)"},
          ],
        },
        {
          id:6,
          name:"A6",
          number:"000006",
          connector:[
            {name:"CEE 7/4 16A SCHUTZKONTAKT"},
            {name:"IEC 60309 32A 2P+E"},
            {name:"IEC 62196 TYPE 2 (Mennekes)"},
          ],
        },
      ],
    },
    {
      siteId:"00003",
      siteName:"Bidakara",
      siteAddress:"Jl. Rasamala Raya No.9, RT.10/RW.8, Menteng Dalam, Kec. Tebet, Kota Jakarta Selatan, Daerah Khusus Ibukota Jakarta 12870",
      siteDescription:"Coming Soon",
      numberOfTotalChargers:32,
      numberOfAvailableChargers:2,
      coordinates:[-6.241621249492955, 106.84133678033395],
      type:1,
      reservation:true,
      chargingPoint:[
        {
          id:1,
          name:"A1",
          number:"000001",
          occupied:false,
          connector:[
            {name:"CEE 7/4 16A SCHUTZKONTAKT"},
            {name:"IEC 60309 32A 2P+E"},
            {name:"IEC 62196 TYPE 2 (Mennekes)"},
          ],
        },
        {
          id:2,
          name:"A2",
          number:"000002",
          occupied:false,
          connector:[
            {name:"CEE 7/4 16A SCHUTZKONTAKT"},
            {name:"IEC 60309 32A 2P+E"},
            {name:"IEC 62196 TYPE 2 (Mennekes)"},
          ],
        },
        {
          id:3,
          name:"A3",
          number:"000003",
          connector:[
            {name:"CEE 7/4 16A SCHUTZKONTAKT"},
            {name:"IEC 60309 32A 2P+E"},
            {name:"IEC 62196 TYPE 2 (Mennekes)"},
          ],
        },
        {
          id:4,
          name:"A4",
          number:"000004",
          connector:[
            {name:"CEE 7/4 16A SCHUTZKONTAKT"},
            {name:"IEC 60309 32A 2P+E"},
            {name:"IEC 62196 TYPE 2 (Mennekes)"},
          ],
        },
        {
          id:5,
          name:"A5",
          number:"000005",
          connector:[
            {name:"CEE 7/4 16A SCHUTZKONTAKT"},
            {name:"IEC 60309 32A 2P+E"},
            {name:"IEC 62196 TYPE 2 (Mennekes)"},
          ],
        },
        {
          id:6,
          name:"A6",
          number:"000006",
          connector:[
            {name:"CEE 7/4 16A SCHUTZKONTAKT"},
            {name:"IEC 60309 32A 2P+E"},
            {name:"IEC 62196 TYPE 2 (Mennekes)"},
          ],
        },
      ],
    },
]