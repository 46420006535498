import Navbar from "../components/Navbar"
import OrderContainer from "../container/OrderContainer"
import { useParams,useNavigate,useSearchParams } from "react-router-dom"
// import { SITES } from "../config/gridSite"
import { SITES } from "../config/gridSiteDemo"
import { useState,useEffect } from "react"


const Order = () => {

    const navigate = useNavigate()

    const [searchParams] = useSearchParams()
    
    const id = searchParams.get('site')
    const chargeId = searchParams.get('charger')
    
    const [siteSelected, setSiteSelected] = useState({})

    const [chargeSelected, setChargeSelected] = useState({})

    // console.log('id is ',id)
    // console.log('chargerId is ',chargeId)

    console.log('charge selected ', chargeSelected)


    useEffect(() => {
        if(!id)
        {
            navigate("/")
            return
        }
        else
        {
            const site = SITES.filter(o => {
                return o.siteId === id
            })

            if (!site[0])
            {
                navigate("/")
                return
            }
            else
            {
                setSiteSelected(site[0])
                
                if (!chargeId)
                {
                    navigate("/")
                    return
                }

                else
                {
                    const chargePoint = site[0].chargingPoint.filter(o => {
                        return o.number === chargeId
                    })

                    if (!chargePoint[0])
                    {
                        navigate('/')
                        return
                    }
                    else
                    {
                        setChargeSelected(chargePoint[0])
                    }
                }
            }

        }
    },[id,chargeId])

    const content = (
        <>
            <Navbar/>
            <OrderContainer site={siteSelected} chargePoint={chargeSelected} />
        </>
    )
  return content
}

export default Order