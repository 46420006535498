import NumberSectionCard from "../components/NumberSectionCard"
import { useInView } from "react-intersection-observer"
import { Fade } from "react-reveal"

const NumberSection = () => {

    const [ref,inView] = useInView({
        threshold:0.3,
        triggerOnce:true
    })

    const defaultStyle = {
        transition: `opacity 300ms ease-in-out`,
        opacity:0
    }

    const transitionStyles = {
        entering: {opacity: 1},
        entered: {opacity: 1},
        exiting: {opacity: 1},
        exited: {opacity: 1},
    }

    const dataCard = [
        {
            number:"46",
            text:"Available Charging Points"
        },
        {
            number:"3",
            text:"Locations"
        },
        {
            number:"2",
            text:"Islands Across Indonesia"
        },
        {
            number:"300",
            symbol:"+",
            text:"kW of Energy Served"
        },
    ]

    const cardContent = dataCard.map(data => <NumberSectionCard key={data.number.concat(data.text)} number={data.number} text={data.text} symbol={data?.symbol}/>)

    const content = (
        <>
            <div className="numbersection">
                <div className="numbersection-bg">
                    <img src="./assets/img/number_bg.webp"/>
                </div>
                <div className="numbersection-container overflow-hidden">
                    {/* bagian ini :: begin */}
                    <Fade bottom duration={1200} delay={800}>
                        <div className='numbersection-img'>
                            <img  src="./assets/img/grid.png"></img>
                        </div>
                    </Fade>
                    {/* bagian ini :: end */}
                    <div className="numbersection-content">
                        {cardContent}
                    </div>
                </div>
            </div>
        </>
    )
  return content
}

export default NumberSection