const BookBottom = ({type,total,summary,totalExTax}) => {

    const onProcessClick = () => {
        if (type == 1)
            window.location.replace(`https://apigrid.bireka.id/booking?harga=${total}`)
        else if (type == 2)
            window.location.replace(`https://apigrid.bireka.id/payment?harga=${total}`)
    }

    const content = (
        <>
            <div className="booking-bottom flex p-4 items-center justify-between lg:hidden bg-white fixed bottom-0 left-0 right-0 h-[70px]">
                <div className="booking-bottom__price font-bold text-lg">
                    <span>Rp.{total},-</span>
                </div>
                <div className="booking-bottom__process">
                    <button className="button" onClick={onProcessClick}>Process</button>
                </div>
            </div>
        </>
    )
    return content
}

export default BookBottom