import BookManual from "../components/BookManual"
import { useAuth } from "../provider/AuthProvider"
import BookSummary from "../components/BookSummary"
import BookBottom from "../components/BookBottom"
import { useEffect,useState } from "react"


const BookingContainer = ({site}) => {

    const { user} = useAuth()
    const bgImage = `${window.location.origin}/assets/img/booking-bg.jpg`

    const [summaryArr, setSummaryArr] = useState([{id:1,label:"Reserve",val:10000},{id:999,label:"PPN",val:1100}])
    const [totalVal,setTotalVal] = useState(11100)
    const [totalNonPpn,setTotalNonPpn] = useState(10000)
    const [manual,setManual] = useState(false)
    const [ppn,setPpn] = useState(1100)

    useEffect(() =>{
        if (manual)
        {
            setSummaryArr([{id:1,label:"Reserve",val:10000},{id:2,label:"Space Pick",val:10000},{id:999,label:"PPN",val:2200}])
            setTotalVal(22200)
            setTotalNonPpn(20000)
        }else
        {
            setSummaryArr([{id:1,label:"Reserve",val:10000},{id:999,label:"PPN",val:1100}])
            setTotalVal(11100)
            setTotalNonPpn(10000)
        }
    },[manual])

    const content = (
        <div className="booking pt-[70px] lg:pt-[100px] min-h-[100vh] bg-slate-100">
            <div className='booking-bg' style={{backgroundImage:`url(${bgImage})`}}>
                <div className='booking-bg__sitename'>
                    <span>{site.siteName}</span>
                </div>
            </div>
            <div className="booking-container max-w-[1000px] mx-auto flex">
                <div className="booking-main">
                    {/* choose park manually */}
                    <div className="booking-main__content p-4 bg-white rounded shadow-md">
                        <div className="booking-main__title text-2xl font-bold pb-4">
                            <span>Booking Detail</span>
                        </div>
                        <div className="booking-info pb-6">
                            <div className="booking-info__title text-xl pb-2">
                                <span>{site.siteName}</span>
                            </div>
                            <div className="booking-info__title">
                                <span>{site.siteAddress}</span>
                            </div>
                        </div>
                        <div className="booking-custinfo pb-8">
                            <div className="booking-custinfo__title font-bold text-xl pb-4">
                                <span>Customer Detail</span>
                            </div>
                            <div className="booking-custinfo__item">
                                <div className="booking-custinfo__label">
                                    <span>Name</span>
                                </div>
                                <div className="booking-custinfo__val">
                                    <span>{user.username}</span>
                                </div>
                            </div>
                            <div className="booking-custinfo__item">
                                <div className="booking-custinfo__label">
                                    <span>ID</span>
                                </div>
                                <div className="booking-custinfo__val">
                                    <span>#0001232</span>
                                </div>
                            </div>
                        </div>
                        <BookManual manualPick={manual} setManualPick={setManual} />
                    </div>
                    {/* customer detail */}
                </div>
                <div className="booking-summary">
                    <BookSummary type={1} summary={summaryArr} totalExTax={totalNonPpn} total={totalVal}/>
                </div>
                <BookBottom  type={1} summary={summaryArr} totalExTax={totalNonPpn} total={totalVal}/>
            </div>
        </div>
    )
    return content
}

export default BookingContainer