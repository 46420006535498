import GroupTextItem from "../components/GroupTextItem"

const GroupText = ({title,subtitle,heading,data}) => {
    let items
    if (data?.length)
    {
        items = data?.length ? data.map(item => <GroupTextItem key={item.title.concat(item.text)} title={item.title} text={item.text} img={item.img} imgList={item.imgList} />) : ""
    }

    const content = (
    <div className="grouptext">
        <div className="grouptext-wrapper">
            <div className="grouptext-head">
                <div className="grouptext-head__heading">
                    <h4>{heading}</h4>
                </div>
                <div className="grouptext-head__subheading">
                    <div className="grouptext-head__title">
                        <h2>{title}</h2>
                    </div>
                    <div className="grouptext-head__subtitle">
                        <h3>{subtitle}</h3>
                    </div>
                </div>
            </div>
            <div className="grouptext-body">
                {items}
            </div>
        </div>
    </div>
    )

    return content
}

export default GroupText