import { Document, Page } from 'react-pdf';
import { useState } from 'react';
import { pdfjs } from 'react-pdf';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

const PdfViewer = ({pdfFile}) => {
    const [numPages, setNumPages] = useState(null);

    const pdfFileShown = pdfFile ? `assets/file/${pdfFile}` : '';

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }


    return (
        <section className='w-full flex justify-center'>
            <div className=''>
                <Document file={pdfFileShown} onLoadSuccess={onDocumentLoadSuccess}>
                    {   
                        Array(numPages).fill().map((_,i) => (
                            <Page key={i} renderAnnotationLayer={false} pageNumber={i + 1}  renderTextLayer={false}/>
                        ))
                    }
                </Document>
            </div>
        </section>
    )
}

export default PdfViewer