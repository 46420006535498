import { useAuth } from '../provider/AuthProvider';
import { useEffect, useState } from 'react';
import { useNavigate,useLocation } from 'react-router-dom';
import { Link } from "react-router-dom"

const Login = () => {

    const bgImage = `${window.location.origin}/assets/img/booking-bg.jpg`
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate()
    const location = useLocation()

    const {user,login} = useAuth()

    useEffect(() => {
        if (user?.username)
        {
            const replacePath = location.state?.path || "/";
            navigate(replacePath)
        }
    },[])
    

    const onChangeUsername = (e) => {
        setUsername(e.target.value)
    }
    
    const onChangePassword = (e) => {
        setPassword(e.target.value)
    }

    const handleLogin = () => {
        login(username,password)
    }

    const content = (
        <>
            <div className='h-[100vh] w-full bg-scroll bg-center bg-no-repeat bg-cover flex justify-center items-center' style={{backgroundImage:`url(${bgImage})`}}>
                <div className='bg-gray-100 p-7 rounded shadow-md'>
                    <div className='flex justify-center pb-8'>
                        <Link to={'/'}>
                            <img className='max-w-none h-[100px]' src={`${window.location.origin}/assets/img/grid.png`} alt="logo-grid" />
                        </Link>
                    </div>
                    <form onSubmit={e => e.preventDefault()}>
                        <div className='form-group'>
                            <label className='w-[30%]' htmlFor="">Username</label>
                            <input className='!w-[65%]' type="text" value={username} onChange={onChangeUsername}/>
                        </div>
                        <div className='form-group'>
                            <label className='w-[30%]' htmlFor="">Password</label>
                            <input className='!w-[65%]' type="password" value={password} onChange={onChangePassword} />
                        </div>
                        <div className='flex'>
                            <button className='button w-full' onClick={handleLogin}>Login</button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
    
    return content
}

export default Login