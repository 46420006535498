import Navbar from '../components/Navbar'
import Footer from '../container/Footer'
import TechnologyContainer from '../container/TechnologyContainer'

import React from 'react'

const Technology = () => {
  return (
    <>
        <Navbar mainPage={0}/>
        <TechnologyContainer />
        <Footer/>
    </>
  )
}

export default Technology