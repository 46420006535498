import "leaflet/dist/leaflet.css"
import { useState, useEffect} from 'react'
import { MapContainer,TileLayer, Marker, useMap} from "react-leaflet"
import Control from "react-leaflet-custom-control";
import SliderControl from '../components/SliderControl'
import L from 'leaflet'
import ModalSite from "./ModalSite";
import { useNavigate , useParams,useSearchParams } from "react-router-dom";
// import { SITES } from "../config/gridSite";
import { SITES } from "../config/gridSiteDemo";

const LocationContainer = ({ parentToggle }) => {

    const [searchParams] = useSearchParams()

    const navigate = useNavigate()
    
    const id = searchParams.get('site')
    const gridSites = SITES

    let coordId = ''

    if (id)
    {
      coordId = gridSites.filter(o => {
        return o.siteId === id
      })
      // console.log(coordId[0]?.coordinates)
    }

    
    
    const coord = coordId[0]?.coordinates ?coordId[0]?.coordinates : [-8.6230229,115.1780071]

    const MapComp = () => {
      const map = useMap()
      setTimeout(()=> {map.invalidateSize()},200)
      return null
    }
    

    const [maxTotal,setMaxTotal] = useState(6)
    
    

    const [modalClass, setModalClass] = useState(false)

    const [containerClass,setContainerClass] = useState('')

    const [siteSelected, setSiteSelected] = useState({})
    const [mapStyle, setMapStyle] = useState('')

    const onCloseClick = () => {
      setModalClass(false)
      setSiteSelected({})
    }

    useEffect(() => {  
      if (id)
      {
        const obj = gridSites.filter(o => {
          return o.siteId === id
        })
        if (obj?.length)
        {
          setSiteSelected(obj[0])
          setModalClass(true)
        }
      }
    },[id])

    useEffect(() => {
      const addClass = parentToggle ? '!h-[100dvh]' : ''
      setContainerClass(addClass)
      setMapStyle('100%')
    },[parentToggle])


    
    const markerOnClick = ((e) => {
      
    })
    // const markerOnClick = ((e) => {
    //   const obj = gridSites.filter(o => {
    //     return o.siteName === e.target.options.siteName
    //   })
    //   if (!parentToggle)
    //   {
    //     navigate(`/map?site=${obj[0].siteId}`)
    //   }
    //   else
    //   {
    //     setModalClass(true)
        
    //     setSiteSelected(obj[0])
    //     return
        
    //   }
    // })

    const navigateHome = () => {
      navigate('/')
    }

    const backToHome = parentToggle ?
      (
        <Control position='bottomleft'>
          <button className="button" onClick={navigateHome}>
            Back To Home
          </button>
        </Control>
      ) : null

    
    const MarkerSPKLU = (() => {

      const iconMarker = new L.Icon({
        iconUrl: `${window.location.origin}/assets/marker/grid_png.png`,
        iconSize: [30,30],
        iconAnchor:[15,15]
      })

      let renderedContent = []

      if (gridSites?.length)
      {
        gridSites.map((location) => {
          if (location.numberOfTotalChargers >= maxTotal)
          {
            renderedContent.push(location)
          }
        })
      }

      const markerContent = renderedContent?.length ?
        renderedContent.map(location => <Marker key={location.siteId} siteName={location.siteName} position={location.coordinates} icon={iconMarker} eventHandlers={{click : markerOnClick}} />) : null
      return markerContent
    })

    
    const content = (
        <div className={`locationcontainer ${containerClass}`}>
            <MapContainer id="map1" center={coord} zoom={12} scrollWheelZoom={false} style={{height:mapStyle}}>
                <Control prepend position='bottomright'>
                  <SliderControl maxTotal={maxTotal} setMaxTotal = {setMaxTotal} />
                </Control>
                {backToHome}
                {/* <MyComponent /> */}
                <MapComp />
                <TileLayer
                    attirbution="openstreetmap"
                    url = 'https://mt0.google.com/vt/lyrs=m&x={x}&y={y}&z={z}'
                />
                <MarkerSPKLU />

            </MapContainer>
            <ModalSite site={siteSelected} active={modalClass} onCloseClick={onCloseClick} />
        </div>
    )
    return content
}

export default LocationContainer