import { useRef, useState, useEffect } from "react"
import MenuContainer from "./MenuContainer"
import { useAuth } from "../provider/AuthProvider";
import { useNavigate } from "react-router-dom";


const Navbar = ({mainPage=1}) => {

    const { user } = useAuth();
    
    const [activeMobileMenu,setActiveMobileMenu] = useState(false)
    const [scrolled, setScrolled] = useState(false)

    const [isLoggedin,setIsLoggedin] = useState(false)

    // console.log('isloggedin is ',isLoggedin)
    // console.log('username is ',user?.username)

    const navigate = useNavigate()

    const onLogoClick = () => {
        navigate('/')
    }

    useEffect(() => {
        const res = (user?.username && (user.username !== '')) ? true : false
        setIsLoggedin(res)
    },[user])

    const changeBg = () => {
        if (window.scrollY > 200)
        {
            setScrolled(true)
        }
        else{
            setScrolled(false)
        }
    }

    window.addEventListener('scroll',changeBg)
    
    const mobilemenuClicked = () => {
        setActiveMobileMenu(!activeMobileMenu)
    }

    const classBgMain = scrolled ? "fixed top-0 left-0 right-0 bg-white shadow-md" : "fixed top-0 left-0 right-0 bg-transparent shadow-none";
    const classBg = mainPage ? classBgMain : "bg-white shadow-md"
  
    const content = (
        <div className={`nav ${classBg}`}>
            <div className='navbar'>
                <div className='navbar-logo cursor-pointer' onClick={onLogoClick}>
                    <img src={`${window.location.origin}/assets/img/grid.png`}/>
                </div>
                <MenuContainer isLogin={isLoggedin} active={activeMobileMenu} onCloseClick={mobilemenuClicked}/>
                <div className='mobilemenu_btn group' onClick={mobilemenuClicked}>
                    <div className='hamburger_btn'>
                        <span className='block h-1 w-8 bg-gray-600 mb-1 rounded-sm group-hover:bg-gray-500'></span>
                        <span className='block h-1 w-8 bg-gray-600 mb-1 rounded-sm group-hover:bg-gray-500'></span>
                        <span className='block h-1 w-8 bg-gray-600 mb-0 rounded-sm group-hover:bg-gray-500'></span>
                    </div>
                </div>
            </div>
        </div>
    )

  return content
}

export default Navbar