import { Fade } from "react-reveal"

const TextContainer = ({heading,text,title,img,comp}) => {

    let secondComp
    

    if (img)
    {
        secondComp = (
            <>
                <Fade right delay={1000} duration={1500}>
                <div className="flex items-center justify-center relative w-[100%] lg:w-[50%] overflow-hidden">
                    <div className="textcontainer-img">
                        <img src={img}  alt="img"/>
                    </div>
                </div>
                </Fade>
            </>
        )
    }
    else{
        if (comp)
        {
            // secondComp should be something else....
            secondComp = (
                <>
                <div className="flex items-center justify-center relative lg:h-[700px] w-[100%] lg:w-[60%] overflow-hidden">
                    {comp}
                </div>
                </>
            )
        }

        else{
            secondComp = (
                <></>
            )
        }
    }

    const headingComp = heading ? (
        <>
            <div className='textcontainer-heading'>
                <span>{heading}</span>
            </div>
        </>
    ) : ""

    const secondCompClass = secondComp ? 'lg:w-[50%]' : ''

    const content = (
        <>
            <div className="textcontainer">
                <div className="textcontainer-wrapper">
                    <div className={`textcontainer-content ${secondCompClass}`}>
                        {headingComp}
                        <div className="textcontainer-content__title">
                            <h3>
                                {title}
                            </h3>
                        </div>
                        <div className="textcontainer-content__text">
                            {text}
                        </div>
                    </div>
                    {secondComp}
                </div>
            </div>
        </>
    )
  return content
}

export default TextContainer